import React from 'react';
import './BlockCard.css'; // CSS file for styling
import BaseComponent from '../base/Base';

export function BlockCardMediumShadow({children, style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="block-card-medium-shadow">
      {children}
    </BaseComponent>
  )
}

function BlockCard({children, style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="block-card">
      {children}
    </BaseComponent>
  )
}


export default BlockCard;