import React from 'react';
import FullPageLayout from '../components2/page/FullPageLayout';
import {Link} from "react-router-dom";
function BlankNotFoundPage(){
  return (
    <FullPageLayout>
      Page not found. Navigate to <Link to="/">Home</Link>
    </FullPageLayout>
  )
}

export default BlankNotFoundPage;