import React from 'react';
import FlexGridRowComponent, {FlexGridColumnComponent, FlexGridItem} from '../layouts/FlexGrid/FlexGridRow'
import TitleFont, { H4 } from '../Text/TitleFont';
import Line from '../icons/lines/Line';

import './NavBar.css'
import { Link } from 'react-router-dom';

function NavBar({header}){
  return (
    <>
      <FlexGridRowComponent className="margin-bottom-1 full-width navbar">
        <FlexGridColumnComponent className="flex-center">
          <TitleFont className="text-center color-palette-2-tx text-font-size-1" >
            {header}
          </TitleFont>
          
          <FlexGridRowComponent className="stick-right width-1-3 flex-col-right">
          <Link to="/" className='no-link-decor'><H4>HOME</H4></Link>
            <H4>SERVICES</H4>
            <H4>Contact</H4>
          </FlexGridRowComponent>
        </FlexGridColumnComponent>
        <Line/>
      </FlexGridRowComponent>
    </>
  )
}

export default NavBar