
import React from 'react';

const ScrollButton = ({ targetRef, children }) => {
  const handleClick = () => {
    targetRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <button onClick={handleClick}>
      {children}
    </button>
  );
};

export default ScrollButton;
