import React, { useState } from 'react';
import './VerticalTab.css'; // CSS file for styling
import { FlexGridColumnComponent } from '../layouts/FlexGrid/FlexGridRow';
import {  TextNormal } from '../Text/TitleFont';

function VerticalTabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    console.log('handleTabClick')
    console.log(index)
    setActiveTab(index);
  };

  return (
    <FlexGridColumnComponent className="vertical-tabs flex-start">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${activeTab === index ? 'active' : ''} text-normal`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <TextNormal className="content">{tabs[activeTab].content}</TextNormal>

      
    </FlexGridColumnComponent>
  );
}

export default VerticalTabs;