import React, {useRef} from "react";
import FullPageLayout from './components2/page/FullPageLayout';
import './layout.css'
import Landing from './pages/landing/Landing';
import ScrollButton from './components2/buttons/scrollButtons/scrollButton';
import BlockCard from './components2/cards/BlockCard';
import FlexGridRowComponent, {FlexGridItem} from './components2/layouts/FlexGrid/FlexGridRow'
import Arrow,{RightArrow} from './components2/icons/arrows/Arrows';
import TitleFont from './components2/Text/TitleFont';
import Line from './components2/icons/lines/Line';
import { Link } from "react-router-dom";
function Home() {
  
  const ref = useRef(null);

  return (
    <>
    <FullPageLayout className="bg1">
      <Landing/>
      <ScrollButton targetRef={ref} >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"/></svg>
      </ScrollButton>
    </FullPageLayout>

    <FullPageLayout targetRef={ref} className="bg2">
      <FlexGridRowComponent>
  
        <FlexGridItem className="flex-center">
          <TitleFont className="text-center color-palette-3-tx" >
            Services
          </TitleFont>
        </FlexGridItem>
          <Line/>

          <Link to="/webdesign" className="no-link-decor">
        <FlexGridItem>
          <BlockCard className="block-card-left border-right-5">
            WEB DESIGN
            <Arrow className="flex-right"/>
          </BlockCard>
        </FlexGridItem>
        </Link>
        <FlexGridItem className="flex-row-right">
          <BlockCard className="block-card-right border-left-5">
            <RightArrow className="flex-left"/>
            Software Development
          </BlockCard>
        </FlexGridItem>
        <FlexGridItem>
          <BlockCard className="block-card-left border-right-5">
            Cloud Consulting
            <Arrow className="flex-right"/>
          </BlockCard>
        </FlexGridItem>
          <Line/>

      </FlexGridRowComponent>
    </FullPageLayout> 

    <FullPageLayout className="bg3">
    <FlexGridRowComponent>
        <FlexGridItem className="flex-center">
          <TitleFont className="text-center color-palette-1-tx" >
            Products
          </TitleFont>
        </FlexGridItem>
          <Line className="color-palette-1-bg"/>

        
          
      </FlexGridRowComponent>
    </FullPageLayout>
    </>
  )
}

export default Home