import React from 'react';
import './FullPageLayout.css'; // CSS file for styling


const FullPageLayout = ({ children, className , targetRef}) => {
  const classes = ['full-page-layout'];

  if (className) {
    classes.push(className);
  }

  return (
    <div ref={targetRef} className={classes.join(' ')}>
      {children}
    </div>
  );
};

export default FullPageLayout;