import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Home from './Home';
import DesignPage from './pages/Design/Design';
import BlankNotFoundPage from './pages/NotFound';

function LayoutBase() {
  return (
    <>
      <Outlet/>
    </>
  )
}

function App() {
  return (
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutBase />}>
          <Route index element={<Home />} />
          <Route path="/webdesign" element={< DesignPage />  } />
          <Route path="*" element={< BlankNotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
