import React from 'react';
// import './Arrow.css'; // CSS file for styling
import BaseComponent from '../../base/Base';

function Arrow({style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="">
      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
        <rect fill="none" height="24" width="24"/>
      <g><polygon points="17.77,3.77 16,2 6,12 16,22 17.77,20.23 9.54,12"/></g>
      </svg>
    </BaseComponent>
  )
}

export function LeftArrow({style, className}) {
  return Arrow
}

export function RightArrow({style, className}) {
  return (
    <BaseComponent className={className} primaryClassName="">
      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/></g></svg>
    </BaseComponent>
  )
}

export default Arrow;