import React from 'react';
import FullPageLayout from '../../components2/page/FullPageLayout';
import FlexGridRowComponent, { FlexGridItem, FlexGridColumnComponent } from '../../components2/layouts/FlexGrid/FlexGridRow';
import BlockCard, {BlockCardMediumShadow} from '../../components2/cards/BlockCard';
import NavBar from '../../components2/navbar/NavBar';
import Line, {VerticalLine} from '../../components2/icons/lines/Line';
import TitleFont, {H2Font} from '../../components2/Text/TitleFont';
import Gap from '../../components2/Margins/Gap';
import VerticalTabs from '../../components2/Tabs/VerticalTab';
import DynamicImageGrid from '../../components2/layouts/ImageGrid/ImageGrid';

function DesignPage() {
  const tabs = [
    {
      label: 'Portfolio Design',
      content: 'Design your portfolio',
    },
    {
      label: 'Landing Page',
      content: 'Supercharge your customer flow using Landing Page',
    },
    {
      label: 'Product Documentation',
      content: 'Set up your product documentation site with ease. Fully markdown',
    },
  ];

  const images = [
    { url: 'images/image1.jpg', alt: 'Image 1' },
    { url: 'images/image1.jpg', alt: 'Image 2' },
    { url: 'images/image1.jpg', alt: 'Image 3' },
    // Add more image objects as needed
  ];

  return (
<>
  <FullPageLayout className="bg2 full-page-layout-flex-start">
    <NavBar header="Web Design" />
    <Line className="line-margin-1px"/>
    <Line className="line-margin-1px"/>

    
    <Gap margin="30px" />

    <FlexGridColumnComponent className="full-width">
      <FlexGridItem className="width-2-3">
        <FlexGridRowComponent className="full-width">
          <FlexGridItem className="flex-center margin-shift-left-20">
            <BlockCardMediumShadow>
              Clean
            </BlockCardMediumShadow>
          </FlexGridItem>
          <FlexGridItem className="flex-center">
            <BlockCard className="block-card-medium-shadow">
              Responsive
            </BlockCard>
          </FlexGridItem>
          <FlexGridItem className="flex-center margin-shift-right-20">
            <BlockCard className="block-card-medium-shadow">
              Powerful
            </BlockCard>
          </FlexGridItem>
        </FlexGridRowComponent>
      </FlexGridItem>
      <FlexGridItem className="width-1-3">
        <VerticalLine/>
        <VerticalLine className="v-line-margin-1px" />
        <VerticalLine className="v-line-margin-1px" />
        <FlexGridRowComponent className="flex-center">
          <FlexGridItem className="flex-center">
          <H2Font className="text-center">
            Invest Once to get maximum ROI
          </H2Font>
          </FlexGridItem>
        </FlexGridRowComponent>
      </FlexGridItem>
    </FlexGridColumnComponent>

    <Gap/>
    <Line className="line-margin-1px"/>
    <Line className="line-margin-1px"/>

    <FlexGridRowComponent className="width-90 margin-1 tab-border">
      <TitleFont className="full-width flex-center text-center camelcase">
        Where can we help you ?
      </TitleFont>
      <Gap/>
      <VerticalTabs tabs={tabs} />
    </FlexGridRowComponent>

    <FlexGridRowComponent className="width-90 margin-1 tab-border">
      <TitleFont className="full-width flex-center text-center camelcase">
        See our works for more inspiration. Visit <a href="https://portfolio.kopoutech.com">Portfolio Section</a>
      </TitleFont>
      <Gap/>
      {/* <FlexGridColumnComponent>
        <DynamicImageGrid images={images} />
      </FlexGridColumnComponent> */}
    </FlexGridRowComponent>


  </FullPageLayout>
</>
  )
}

export default DesignPage