import React from 'react';

function Landing() {
  return (
    <>
      <div className="flex-grid tborder">
        <div className="letter-item letter-portrait it-1">K</div>
        <div className="letter-item letter-portrait it-2">O</div>
        <div className="letter-item letter-portrait it-3">P</div>
        <div className="letter-item letter-portrait it-4">O</div>
        <div className="letter-item letter-portrait it-5">U</div>
        <div className="letter-item letter-portrait it-6">T</div>
        <div className="letter-item letter-portrait it-7">E</div>
        <div className="letter-item letter-portrait it-8">C</div>
        <div className="letter-item letter-portrait it-9">H</div>
      </div>
    </>
  )
}

export default Landing